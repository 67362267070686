
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Md5 } from 'ts-md5/dist/md5'
import {
  getProductSpecificationsList,
  editProductSpecifications,
  addProductSpecifications,
  isEditAuthByCode,
  getModelList
} from '@/api/index'
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  alarmSwitchOption: any
  hibernatesOption:any
  customerData: Array<any>
  editFlag: boolean
  editForm: any
  modelOption:any
  searchDataAny: any //搜索条件
}

export default defineComponent({
  name: 'ProductSpecificationsTable',
  components: {},
  setup() {
    const productSpecificationsRef = ref()
    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件

      customerData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      modelOption: [],
      alarmSwitchOption: [
        {
          value: '1',
          label: '低电',
        },
        {
          value: '2',
          label: '外电断电',
        },
        {
          value: '3',
          label: '盲区',
        },
        {
          value: '4',
          label: '紧急',
        },
        {
          value: '5',
          label: '移位',
        },
        {
          value: '6',
          label: '超速',
        },
        {
          value: '7',
          label: '油量检测',
        },
        {
          value: '8',
          label: '震动',
        },
        {
          value: '9',
          label: '拆除报警开关',
        }
      ],
      hibernatesOption: [
          //0-长在线，1-振动休眠，2-时间休眠，3-深度振动休眠，4-定时报告，5-深度振动+定时报告休眠
        {
          value: '0',
          label: '常在线',
        },
        {
          value: '1',
          label: '震动休眠',
        },
        {
          value: '2',
          label: '时间休眠',
        },
        {
          value: '3',
          label: '深度振动休眠',
        },
        {
          value: '4',
          label: '深度定时报告',
        },
        {
          value: '5',
          label: '深度振动休眠+定时报告',
        }
        ,{
          value: '6',
          label: '自行车模式1',
        }
        ,{
          value: '7',
          label: '自行车模式2',
        },
        {
          value: '8',
          label: '定时报告',
        }
      ],
      rules: {
        name: [{ required: true, message: '请输入规格名称', trigger: 'blur' }],
      },
    })

    const data = toRefs(deviceData)
    onMounted(() => {
          setTimeout(function(){
              getList()
          },50);
    })

    const addFun = () => {
        deviceData.editForm = { isEdit: false }
        deviceData.modelOption = [];
        getModelList({ isPage: false }).then(
        (res: any) => {
            if (res.data != null) {
                //deviceData.modelOption = res.data.list
                for (let o of res.data.list) {
                    console.info("xxxxxxxxxxxxxxxx");
                    deviceData.modelOption.push({
                    value: o.id,
                    label: o.name
                });
                }
                
            }
        }
    )
      
      deviceData.editFlag = true
    }

    const editSave = async () => {
      productSpecificationsRef.value.validate(async (valid: any) => {
        if (valid) {
          let fun = addProductSpecifications
          if (deviceData.editForm.isEdit) {
            fun = editProductSpecifications
            delete deviceData.editForm.createTime
          }

          let array: any = {}
          Object.assign(array, deviceData.editForm)
            console.log("xxxx");
          if(deviceData.editForm.alarmSwitch!=null){
              array.alarmSwitch = deviceData.editForm.alarmSwitch + ''
          }
          
          if(deviceData.editForm.hibernates!=null){
              array.hibernates = deviceData.editForm.hibernates + ''
          }
          
          let keys = Object.keys(array);
          for (let key of keys) {
              if(array[key] == null && key !='alarmSwitch' && key !='hibernates'){
                array[key] = 0;
              }
          }

          fun(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.errMsg)
            }
          })
        } else {
          return false
        }
      })
    }

    const selectCustomer = () => {
      deviceData.currentPage = 1
      getList()
    }

    //编辑
    const editorFun = (e: any) => {
      //console.log(e)
      let obj: any = {}
      Object.assign(obj, e)

      deviceData.editForm = obj
      deviceData.editForm.isEdit = true
      if (e.alarmSwitch != null && e.alarmSwitch != '') {
        deviceData.editForm.alarmSwitch = e.alarmSwitch.split(',')
      }
      if (e.hibernates != null && e.hibernates != '') {
        deviceData.editForm.hibernates = e.hibernates.split(',')
        }
      deviceData.modelOption.push({
          value: obj.tbDevModelId,
          label: obj.name,
        });
      deviceData.editFlag = true
    }

    const getList = async () => {
        //deviceData.customerData = [];
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getProductSpecificationsList(deviceData.searchDataAny).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.customerData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.customerData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
          
            // ElMessage.success('加载成功!')
          }else if(res.errMsg!=null){
            ElMessage.error(res.errMsg);
              deviceData.loading = false
        }else{
            ElMessage.error("加载失败");
              deviceData.loading = false
        }
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }

    const formatAlarmSwitch = (val: any) => {
      if (val == null || val == '') {
        return val
      }
      let array = val.split(',')
      let str = ''
      for (let v of array) {
        for (let obj of deviceData.alarmSwitchOption) {
          if (obj.value == v) {
            if (str.length > 0) {
              str += ','
            }
            str += obj.label
          }
        }
      }
      return str
    }
    
    const formatHibernates = (val: any) => {
      if (val == null || val == '') {
        return val
      }
      let array = val.split(',')
      let str = ''
      for (let v of array) {
        for (let obj of deviceData.hibernatesOption) {
          if (obj.value == v) {
            if (str.length > 0) {
              str += ','
            }
            str += obj.label
          }
        }
      }
      return str
    }

    return {
      ...data,
      addFun,
      productSpecificationsRef,
      editSave,
      selectCustomer,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
      formatAlarmSwitch,
      isEditAuthByCode,
      formatHibernates
    }
  },
})
